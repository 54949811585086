import React, { useCallback } from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Components
import Layout from '../../components/layout/Layout';
import ServicesSection from '../../components/section/Services';
import PlansSection from '../../components/section/Plans';
import RealisationsSection from '../../components/section/Realisations';
import TestimonialsSection from '../../components/section/Testimonials';
import ContactForm from '../../components/form/Contact';

// Images
import headerBackgroundImage from '../../images/svg/components/card-11.svg';
import backgroundImage from '../../images/svg/components/wave-pattern-light.svg';

// Local Business
const DigitalCommunicationLocalBusinessTemplate = ({ pageContext, data }) => {

  const { t } = useTranslation();
  const { language } = useI18next();

  const location = useLocation();
  const origin = location.origin;

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  const pageImage = {
    alt: t('image.alt', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })
  };

  const pageSchemas = [
    {
      type: "breadcrumb",
      listItems: [
        {
          name: t('breadcrumb.cities'),
          item: origin + (language === 'fr-fr' ? `/` : `/${language}/`) + "cities/"
        },
        {
          name: t('meta.title', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })
        },
      ]
    }
  ];

  return (
    <Layout
      pageType="custom"
      pageTitle={t('meta.title', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title, city_name: data.city.name, department: data.city.department, postal_code: data.city.postal_code })}
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div>
        <div className="position-relative bg-img-start" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} data-aos="fade-up">
          <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-3 position-relative zi-2">
            <div className="row justify-content-lg-between align-items-md-center d-none d-md-flex">
              <div className="col-md-6 col-lg-5 mb-10 mb-md-0">
                <div className="mb-6">
                  <h1 className="h1 display-6 mb-3" dangerouslySetInnerHTML={{ __html: t('title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h1>
                  <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row justify-content-end">
                  <div className="col-3 mb-4">
                    <div className="d-block avatar avatar-lg rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-3 mt-n3 ms-5" data-aos="fade-up">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/google-icon.svg" alt={t('google')} />
                    </div>
                  </div>
                  <div className="col-4 mb-4">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 mx-auto mt-5" data-aos="fade-up" data-aos-delay="50">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/facebook-icon.svg" alt={t('facebook')} />
                    </div>
                  </div>
                  <div className="col-4 mb-4">
                    <div className="d-block avatar avatar-xxl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="150">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/instagram-icon.svg" alt={t('instagram')} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 offset-1 my-4">
                    <div className="d-block avatar avatar-xxl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="200">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/linkedin-icon.svg" alt={t('linkedin')} />
                    </div>
                  </div>
                  <div className="col-3 offset-2 my-4">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="250">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/tiktok-icon.svg" alt={t('tiktok')} />
                    </div>
                  </div>
                </div>
                <div className="row d-none d-md-flex">
                  <div className="col-6">
                    <div className="d-block avatar avatar-lg rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-3 ms-auto" data-aos="fade-up" data-aos-delay="300">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/pinterest-icon.svg" alt={t('pinterest')} />
                    </div>
                  </div>
                  <div className="col-6 mt-6">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="350">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/tripadvisor-icon.svg" alt={t('tripadvisor')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-lg-between align-items-md-center d-md-none d-flex">
              <div className="mb-10 mb-md-0">
                <div className="mb-6">
                  <h1 className="h1 display-6 mb-3" dangerouslySetInnerHTML={{ __html: t('title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h1>
                  <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="row justify-content-end">
                  <div className="col-3 mb-4">
                    <div className="d-block avatar avatar-lg rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-3 mt-n3 ms-5" data-aos="fade-up">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/webflow-icon.svg" alt={t('webflow')} />
                    </div>
                  </div>
                  <div className="col-4 mb-4">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 mx-auto mt-5" data-aos="fade-up" data-aos-delay="50">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/woocommerce-icon.svg" alt={t('woocommerce')} />
                    </div>
                  </div>
                  <div className="col-4 mb-4">
                    <div className="d-block avatar avatar-xxl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="150">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/squarespace-icon.svg" alt={t('squarespace')} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 offset-1 my-4">
                    <div className="d-block avatar avatar-xxl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="200">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/prestashop-icon.svg" alt={t('prestashop')} />
                    </div>
                  </div>
                  <div className="col-3 offset-2 my-4">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="250">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/shopify-icon.svg" alt={t('shopify')} />
                    </div>
                  </div>
                </div>
                <div className="row d-none d-md-flex">
                  <div className="col-6">
                    <div className="d-block avatar avatar-lg rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-3 ms-auto" data-aos="fade-up" data-aos-delay="300">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/wix-icon.svg" alt={t('wix')} />
                    </div>
                  </div>
                  <div className="col-6 mt-6">
                    <div className="d-block avatar avatar-xl rounded-circle mx-auto d-flex justify-content-center flex-wrap shadow-sm p-4 ms-auto" data-aos="fade-up" data-aos-delay="350">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/bootstrap-icon.svg" alt={t('bootstrap')} />
                    </div>
                  </div>
                </div>
                <span className="text-cap mb-0">{t('partners.title', { title: data.site.siteMetadata.title })}</span>
                <div className="row">
                  <div className="col py-3">
                    <a href="https://partnernetwork.ionos.fr/partner/la.small.agency?origin=PartnerBadge" target="_blank" rel="noopener noreferrer">
                      <StaticImage imgClassName="img-fluid" quality="30" src="../../images/brands/ionos-agency-partner-icon.svg" alt={t('partners.ionos.image.alt', { title: t('common.image.alt') })} width={120} />
                    </a>
                  </div>
                </div>
                <div className="d-grid d-sm-flex gap-3">
                  <Link className="btn btn-primary btn-transition" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-bottom zi-1">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 500H3000V0L0 500Z" fill="#fff" />
            </svg>
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3" id="services">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('services.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
            <p>{t('services.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
          </div>
          <ServicesSection data={data} service="local_business_digital_communication" />
        </div>
        <div className="container content-space-t-2 content-space-b-md-2 content-space-t-lg-4">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('benefits.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
            <p>{t('benefits.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.visibility.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                  <p className="card-text text-body">{t('benefits.visibility.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.39961 20.5073C7.29961 20.5073 6.39961 19.6073 6.39961 18.5073C6.39961 17.4073 7.29961 16.5073 8.39961 16.5073H9.89961C11.7996 16.5073 13.3996 14.9073 13.3996 13.0073C13.3996 11.1073 11.7996 9.50732 9.89961 9.50732H8.09961L6.59961 11.2073C6.49961 11.3073 6.29961 11.4073 6.09961 11.5073C6.19961 11.5073 6.19961 11.5073 6.29961 11.5073H9.79961C10.5996 11.5073 11.2996 12.2073 11.2996 13.0073C11.2996 13.8073 10.5996 14.5073 9.79961 14.5073H8.39961C6.19961 14.5073 4.39961 16.3073 4.39961 18.5073C4.39961 20.7073 6.19961 22.5073 8.39961 22.5073H15.3996V20.5073H8.39961Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M8.89961 8.7073L6.69961 11.2073C6.29961 11.6073 5.59961 11.6073 5.19961 11.2073L2.99961 8.7073C2.19961 7.8073 1.7996 6.50732 2.0996 5.10732C2.3996 3.60732 3.5996 2.40732 5.0996 2.10732C7.6996 1.50732 9.99961 3.50734 9.99961 6.00734C9.89961 7.00734 9.49961 8.0073 8.89961 8.7073Z" fill="#035A4B"></path>
                      <path d="M5.89961 7.50732C6.72804 7.50732 7.39961 6.83575 7.39961 6.00732C7.39961 5.1789 6.72804 4.50732 5.89961 4.50732C5.07119 4.50732 4.39961 5.1789 4.39961 6.00732C4.39961 6.83575 5.07119 7.50732 5.89961 7.50732Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M17.3996 22.5073H15.3996V13.5073C15.3996 12.9073 15.7996 12.5073 16.3996 12.5073C16.9996 12.5073 17.3996 12.9073 17.3996 13.5073V22.5073Z" fill="#035A4B"></path>
                      <path d="M21.3996 18.5073H15.3996V13.5073H21.3996C22.1996 13.5073 22.5996 14.4073 22.0996 15.0073L21.2996 16.0073L22.0996 17.0073C22.6996 17.6073 22.1996 18.5073 21.3996 18.5073Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.attractiveness.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                  <p className="card-text text-body">{t('benefits.attractiveness.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-4 mb-3 mb-sm-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="#035A4B"></path>
                      <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982V15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442V19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.seoFriendly.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                  <p className="card-text text-body">{t('benefits.seoFriendly.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="gradient-y-sm-primary overflow-hidden" id="checklist">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
            </div>
            <div className="w-md-100 mx-md-auto">
              <div className="row justify-content-lg-center align-items-md-center">
                <div className="col-12 col-md-4 mb-7 m-md-0">
                  <div className="position-relative">
                    <div className="card">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 4C20.3284 4 21 4.67157 21 5.5V17.5C21 18.3284 20.3284 19 19.5 19H4.5C3.67157 19 3 18.3284 3 17.5V14.5C3 13.6716 3.67157 13 4.5 13H9V9.5C9 8.67157 9.67157 8 10.5 8H15V5.5C15 4.67157 15.6716 4 16.5 4H19.5Z" fill="#035A4B"></path>                          </svg>
                        </span>
                        <h3 className="card-title">{t('checklist.card.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h3>
                        <p className="card-text">{t('checklist.card.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                    <figure className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{ width: '12rem' }}>
                      <StaticImage className="img-fluid" quality="30" src="../../images/svg/components/dots-lg.svg" alt={t('checklist.image.alt', { title: t('common.image.alt') })} />
                    </figure>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row justify-content-lg-center align-items-md-center">
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.restaurant')}</li>
                        <li className="list-checked-item">{t('checklist.cafe')}</li>
                        <li className="list-checked-item">{t('checklist.bar')}</li>
                        <li className="list-checked-item">{t('checklist.bakery')}</li>
                        <li className="list-checked-item">{t('checklist.bookStore')}</li>
                        <li className="list-checked-item">{t('checklist.concertVenue')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.grocery')}</li>
                        <li className="list-checked-item">{t('checklist.hotel')}</li>
                        <li className="list-checked-item">{t('checklist.movieTheatre')}</li>
                        <li className="list-checked-item">{t('checklist.museum')}</li>
                        <li className="list-checked-item">{t('checklist.artGallery')}</li>
                        <li className="list-checked-item">{t('checklist.realEstate')}</li>
                        <li className="list-checked-item">{t('checklist.school')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.spas')}</li>
                        <li className="list-checked-item">{t('checklist.personalCare')}</li>
                        <li className="list-checked-item">{t('checklist.automobiles')}</li>
                        <li className="list-checked-item">{t('checklist.consulting')}</li>
                        <li className="list-checked-item">{t('checklist.nutritionist')}</li>
                        <li className="list-checked-item">{t('checklist.beauty')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.insurance')}</li>
                        <li className="list-checked-item">{t('checklist.software')}</li>
                        <li className="list-checked-item">{t('checklist.legal')}</li>
                        <li className="list-checked-item">{t('checklist.nonProfitOrganization')}</li>
                        <li className="list-checked-item">{t('checklist.media')}</li>
                        <li className="list-checked-item">{t('checklist.travel')}</li>
                      </ul>
                    </div>
                  </div>
                  <AnchorLink className="link" href="#plans">{t('cta.ourPlans')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-discovery" id="process-discovery-tab" data-bs-toggle="tab" data-bs-target="#process-discovery" role="tab" aria-controls="process-discovery" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.discovery.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.discovery.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-plan" id="process-plan-tab" data-bs-toggle="tab" data-bs-target="#process-plan" role="tab" aria-controls="process-plan" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="#035A4B"></path>
                          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.plan.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.plan.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-publish" id="process-publish-tab" data-bs-toggle="tab" data-bs-target="#process-publish" role="tab" aria-controls="process-publish" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.publish.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.publish.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-optimization" id="process-optimization-tab" data-bs-toggle="tab" data-bs-target="#process-optimization" role="tab" aria-controls="process-optimization" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z" fill="#035A4B"></path>
                          <path d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.optimization.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</div>
                        <p className="text-body mb-0">{t('process.optimization.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/digital-communication/la-small-agency-digital-communication-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-discovery" role="tabpanel" aria-labelledby="process-discovery-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/digital-communication/la-small-agency-digital-communication-process-discovery.svg" alt={t('process.discovery.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.discovery.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-plan" role="tabpanel" aria-labelledby="process-plan-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/digital-communication/la-small-agency-digital-communication-process-plan.svg" alt={t('process.plan.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.plan.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-publish" role="tabpanel" aria-labelledby="process-publish-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/digital-communication/la-small-agency-digital-communication-process-publish.svg" alt={t('process.publish.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.publish.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
                <div className="tab-pane fade" id="process-optimization" role="tabpanel" aria-labelledby="process-optimization-tab">
                  <StaticImage quality="30" src="../../images/illustrations/local-business/digital-communication/la-small-agency-digital-communication-process-optimization.svg" alt={t('process.optimization.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.optimization.text', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="bg-light">
          <div className="content-space-2 position-relative zi-2 overflow-hidden">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <span className="text-cap">{t('socialNetworks.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</span>
              <h2 dangerouslySetInnerHTML={{ __html: t('socialNetworks.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
            </div>
            <Swiper
              className="w-75 p-6"
              modules={[Navigation, A11y, Autoplay]}
              navigation={true}
              pagination={{
                dynamicBullets: false,
                clickable: true
              }}
              scrollbar={{ draggable: true }}
              breakpoints={{
                "380": {
                  "slidesPerView": 1,
                  "spaceBetween": 15,
                },
                "580": {
                  "slidesPerView": 2,
                  "spaceBetween": 15,
                },
                "768": {
                  "slidesPerView": 3,
                  "spaceBetween": 15,
                },
                "1024": {
                  "slidesPerView": 4,
                  "spaceBetween": 30,
                }
              }}
            >
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#F4B400' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/google-icon.svg" alt={t('socialNetworks.google.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.google.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.google.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#0165E1' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/facebook-icon.svg" alt={t('socialNetworks.facebook.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.facebook.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.facebook.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#833AB4' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/instagram-icon.svg" alt={t('socialNetworks.instagram.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.instagram.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.instagram.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#0A66C2' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/linkedin-icon.svg" alt={t('socialNetworks.linkedin.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.linkedin.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.linkedin.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#25F4EE' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/tiktok-icon.svg" alt={t('socialNetworks.tiktok.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.tiktok.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.tiktok.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#E60023' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/pinterest-icon.svg" alt={t('socialNetworks.pinterest.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.pinterest.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.pinterest.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#FBEB24' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/pagesjaunes-icon.svg" alt={t('socialNetworks.pagesjaunes.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.pagesjaunes.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.pagesjaunes.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#00AF87' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/tripadvisor-icon.svg" alt={t('socialNetworks.tripadvisor.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.tripadvisor.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.tripadvisor.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="my-2">
                <div className="card card-sm card-transition shadow-sm">
                  <div className="card-img-top" style={{ backgroundColor: '#4d8f35' }}>
                    <div className="m-4 text-center">
                      <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                        <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../../images/brands/thefork-icon.svg" alt={t('socialNetworks.thefork.title')} />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">{t('socialNetworks.thefork.title')}</div>
                    <p className="card-text text-body">{t('socialNetworks.thefork.subtitle')}</p>
                    <div className="d-grid mt-3">
                      <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="shape shape-bottom">
              <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 500H3000V0L0 500Z" fill="#fff" />
              </svg>
            </div>
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('plans.title', { title: data.site.siteMetadata.title, city_name: data.city.name }) }}></h2>
            <p>{t('plans.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
          </div>
          <PlansSection data={data} plan={data.plan.frontmatter} service="local_business_digital_communication" />
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="bg-dark rounded-2" style={{ backgroundImage: 'url(' + backgroundImage + ')' }} >
            <div className="px-4 px-md-8 px-lg-10">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-5 col-md-10  mb-9 mt-9 mb-lg-0 mt-lg-0">
                  <h1 className="text-white">{t('contact.title', { title: data.site.siteMetadata.title, city_name: data.city.name })}</h1>
                  <p className="text-white-70">{t('contact.subtitle', { title: data.site.siteMetadata.title, city_name: data.city.name })}</p>
                  <div className="border-top border-white-10 my-5" style={{ maxWidth: '10rem' }}></div>
                  <div className="row">
                    {data.site.siteMetadata.telephone ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-telephone-inbound-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.callUs')}</h4>
                        <a className="text-white-70 small" href={"tel:" + data.site.siteMetadata.telephoneIndex}>{data.site.siteMetadata.telephone}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.email ?
                      <div className="col-sm-6 mb-5">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-envelope-open-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.emailUs')}</h4>
                        <a className="text-white-70 small" href={"mailto:" + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a>
                      </div>
                      : null}
                    {data.site.siteMetadata.addressFull ?
                      <div className="col-sm-6">
                        <span className="icon icon-soft-light icon-circle mb-3">
                          <i className="bi-geo-alt-fill"></i>
                        </span>
                        <h4 className="text-white mb-0">{t('contact.visitUs')}</h4>
                        <a className="text-white-70 small" href={"http://maps.google.com/maps?daddr=" + data.site.siteMetadata.addressLatitude + "%2C" + data.site.siteMetadata.addressLongitude} target="_blank" rel="noopener noreferrer">{data.site.siteMetadata.addressFull}</a>
                      </div>
                      : null}
                  </div>
                </div>
                <div className="col-lg-7 mb-9 mt-9 mb-lg-n10 mt-lg-n10">
                  <div className="card card-lg">
                    <div className="card-header bg-light text-center">
                      <h3 className="card-title">{t('contact.text')}</h3>
                    </div>
                    <div className="card-body">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

DigitalCommunicationLocalBusinessTemplate.propTypes = {
  pageContext: PropTypes.shape({
    name_slug: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    city: PropTypes.shape({
      name: PropTypes.string.isRequired,
      name_slug: PropTypes.string.isRequired,
    }).isRequired
  }),
}

export default DigitalCommunicationLocalBusinessTemplate

export const query = graphql`
  query ($name_slug: String, $language: String!) {
    site: site {
      siteMetadata {
        title
        image {
          url
          secureUrl
          type
          width
          height
        }
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    city : citiesCsv(name_slug: {eq: $name_slug}) {
      name
      name_capital
      name_lowercase
      name_slug
      department
      postal_code
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "local-business-digital-communication", "plans", "categories", "tools", "contact-form", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["social-media-optimization", "community-management", "email-signature"] }, home: { eq: true }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { services: { in: ["social-media-optimization", "community-management", "email-signature"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "local_business_digital_communication"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
      }
      id
    }
  }
`